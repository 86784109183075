<template>
<div class="wh-100">
    <section class="p-2 wh-100">
        <front-table :front_page="page"
                     :front_records="records"
                     :front_search="search"
                     :front_header="header"
                     :front_extra="extra_filters"
                     :model="model"
                     @reload="handleReload"
                     @edit="handleEdit"
                     :btn_add = "can_add"
                     :btn_delete="false"
                     :btn_edit="can_edit"
                     :show_loading = false
        >
        </front-table>
        <!--编辑界面-->
        <el-dialog class="front-dialog" :title="single.id?'编辑记录':'发布公告'" :visible.sync="edit_mode"
            :close-on-click-modal="false"  width="75%">
            <el-form :model="single" ref="edit_form" label-width="100px" :rules="editRules" >
                <el-row v-if="single.id">
                    <el-col :span="12" >
                        <front-single-form-editor :single="single" :item="fields.user_name"
                                                  field="user_name">
                        </front-single-form-editor>
                    </el-col>
                    <el-col :span="12">
                        <front-single-form-editor :single="single" :item="fields.private"
                                                  field="private">
                        </front-single-form-editor>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24" v-if="single.id">
                        <front-single-form-editor :single="single" :item="fields.comment"
                                                  field="comment">
                        </front-single-form-editor>
                    </el-col>
                    <el-col :span="24">
                        <front-fake-form-item :label="single.id?'回答':'公告'" :width="100">
                            <el-input v-model="single.answer"
                                      placeholder="请输入"
                                      type="textarea"
                                      size="small"
                            >
                            </el-input>
                        </front-fake-form-item>
                    </el-col>
                </el-row>
                <el-row >
                    <el-col :span="24">
                        <front-single-form-editor :single="single" :item="fields.published"
                                                  field="published">
                        </front-single-form-editor>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="">
                <slot name="footer"></slot>
                <el-button @click.native="edit_mode=false" icon="el-icon-close" size="medium">放弃</el-button>
                <el-button type="primary" @click.native="editSubmit" icon="el-icon-check" :loading="process.loading" size="medium">提交
                </el-button>
            </div>
        </el-dialog>

        <div class="clearfix"></div>

    </section>
</div>
</template>

<script>
import FrontTable from "@/components/front-table";
import FrontMixins from "@/common/mixins/front-mixins";
import FrontSingleFormEditor from "@/components/front-single-form-editor";
import FrontFakeFormItem from "@/components/front-fake-form-item";

export default {
    name: "comment-view",
    mixins: [FrontMixins],
    data(){
        return {
            timer:0
        }
    },
    components: {FrontTable,FrontSingleFormEditor,FrontFakeFormItem},
    props:{
        program:{
            type:Object,
            default(){
                return {};
            }
        },
    },
    computed:{
        editRules() {
            let rules = {};
            for (let x in this.fields) {
                const field = this.fields[x];
                if (field.validation) {
                    rules[x] = [];
                    field.validation.forEach(item => {
                        const trigger = ['text', 'textarea', 'number'].includes(item.type) ? 'blur' : 'change';
                        if (item === 'required') {
                            rules[x].push({
                                required: true,
                                message: this.english ? ((field.title_en || field.title) + " is required!") : ('请输入' + field.title),
                                trigger: trigger
                            });
                        }
                        if (item.indexOf('min:') === 0 || item.indexOf('max:') === 0) {
                            let cmd = item.split(':');
                            if (cmd.length > 1) {
                                let v = {
                                    message: (cmd[0] === 'min' ? ('最小') : ('最大')) + cmd[1] + ('个字'),
                                    trigger: 'blur'
                                };
                                v[cmd[0]] = parseInt(cmd[1]);
                                rules[x].push(v);
                            }
                        }
                        if (item.indexOf('email') === 0) {
                            rules[x].push({
                                type: 'email',
                                message: ('请输入正确的邮箱格式'),
                                trigger: trigger
                            });
                        }
                    });
                }
            }
            return rules;
        },
    },
    methods:{
        handleEdit(id) {
            let vm = this;
            this.$api(vm.model + '.Get', {id: id,uuid:this.search.program_uuid}, true).then(response => {
                vm.single = response.data.data.data || {};
                vm.fields = response.data.data.fields;
                vm.edit_mode = true;
            });
        },
        editSubmit() {
            let vm = this;
            if (this.model) {
                this.$refs['edit_form'].validate(valid => {
                    if (valid) {
                        this.single['uuid'] = this.search.program_uuid;
                        this.$api(this.model + '.Edit', this.single, true).then(() => {
                                vm.edit_mode = false;
                                this.$notice.success('记录更新成功!');
                                this.reload();
                            }
                        );
                    } else {
                        this.$notice.error('请按照要求填写数据!');
                    }
                });
            }
        },
    },
    created(){
        this.model = "Admin.CommentView";
        this.$set(this.search,'program_uuid',this.program.uuid);
    },
    mounted() {
        const vm=this;
        this.timer = setInterval(function (){
            vm.reload();
        },30000);
    },
    beforeDestroy() {
        if (this.timer) {
            clearInterval(this.timer);
            this.timer = 0;
        }
    }
}
</script>

<style scoped>

</style>
