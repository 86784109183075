<template>
    <div class="w-100 h-100">
        <div class="w-100 h-100" v-if="content_ready">
            <console-live-page :single="single" @reload="reload"></console-live-page>
        </div>
        <div v-else class="h-100">
            <play-error-page v-if="error" :single="single" :error="error"></play-error-page>
            <div v-else class="flex-in-middle h-100" style="font-size: 28px;">
                <i class="el-icon-loading"></i>
                <span class="text-muted ml-3">{{ $t("MESSAGE.WAIT") }}</span>
            </div>
        </div>
    </div>
</template>

<script>

import PlayErrorPage from "@/components/play-error-page";
import ConsoleLivePage from "@/components/console-live-page";
import CommonMixins from "@/common/mixins/common-mixin";
export default {
    components: {ConsoleLivePage, PlayErrorPage},
    mixins: [CommonMixins],
    data() {
        return {
            content_ready: false,
            single: null,
            error: null,
            mode: 'WAIT',
            state: 1,
            timer: 0,
        }
    },
    mounted() {
        // 每分钟都要做reload
        if (this.user.role_level < 4) {
            this.$router.replace({name: 'prohibit'})
        } else {
            const vm = this;
            this.timer = setInterval(function () {
                vm.reload();
            }, 30000);
            this.reload();
        }
    },
    computed: {
        status() {
            let ret = 0;
            if (this.single) {
                const start = this.single.start_timestamp;
                const end = this.single.end_timestamp;
                const now = new Date().getTime() / 1000;
                if (start > now) ret = 0;
                else if (now > end) ret = 2;
                else ret = 1;
            }
            return ret;
        }
    },
    methods: {
        reload(force_update) {
            const vm = this;
            const passcode = localStorage.getItem(this.$route.params.id + '_passcode');
            this.$api('ADMIN.PROGRAM.CONSOLE', {
                uuid: this.$route.params.id,
                passcode: passcode,
                state: this.state
            }).then(res => {
                if (res.data.success) {
                    const program = res.data.data;
                    if (!this.single ||
                        program.description !== this.single.description ||
                        program.start_timestamp !== this.single.start_timestamp ||
                        program.end_timestamp !== this.single.end_timestamp ||
                        program.close_action !== this.single.close_action ||
                        program.dvr_file !== this.single.dvr_file || force_update
                    ) {
                        this.single = program;
                        if (this.status === 0) this.mode = 'WAIT';
                        else if (this.status === 1) this.mode = 'PLAY';
                        else this.mode = "END"
                        this.content_ready = true;
                    } else {
                        this.$set(this.single,'online',program.online);
                    }
                } else {
                    vm.error = res.data.text;
                    vm.content_ready = false;
                    vm.single = res.data.data;
                }
                this.state = 2
            });
        },
        handlePasscode(passcode) {
            localStorage.setItem(this.$route.params.id + '_passcode', passcode);
            this.reload();
        },
        handlePlayFromWait() {
            this.mode = "";
            this.$nextTick(() => {
                //this.reload(true);
                this.mode = "PLAY";
            })
        },
        handleEndFromPlay() {
            this.mode = "";
            this.$nextTick(() => {
                this.mode = "END";
            })
        }
    },
    beforeDestroy() {
        if (this.timer) {
            clearInterval(this.timer);
            this.timer = 0;
        }
    },
}

</script>

