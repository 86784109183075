<template>
    <section class="wh-100" style="background-color: #F5f5f5">
        <div class="wh-100" v-if="content_ready">
            <!-- 结束界面 -->
            <div class="wh-100" v-if="mode==='END' && single.close_action != 0">
                <public-frame-dark class="d-flex flex-column" style="min-height: 460px;">
                    <div class="flex-in-middle h-100" v-if="single.close_action == '1'">
                        <h1>{{ $t("MESSAGE.EVENT-CLOSED") }}</h1>
                    </div>
                    <div class="wh-100 position-relative" v-else v-lazy:background="single.ending_picture_url"
                         style="background-size: cover">
                        <div class="backdrop-blur"></div>
                        <div class="flex-in-middle position-absolute" style="left:0;right: 0;height: 100%;width: 100%">
                            <img :src="single.ending_picture_url" class="responsive-image" alt="">
                        </div>
                    </div>
                </public-frame-dark>
            </div>
            <!-- 播放界面 -->
            <div v-else class="mobile-play">
                <div class="play-window-frame" :style="{padding:play_window_padding}" style="background-color: black;">
                    <div class="frame-16-9">
                        <div class="inner" v-lazy:background="single.picture_url" v-if="mode==='WAIT'">
                            <div class="wh-100" style="background-color: RGBA(0,0,0,0.5)"></div>
                            <div class="position-absolute wh-100" style="top: 0;left: 0;">
                                <h3 class="mt-4 text-center text-white">{{ $t('MESSAGE.EVENT-START') }}</h3>
                                <flip-down :endDate="single.start_timestamp * 1000" @timeUp="handlePlayFromWait"
                                           :timeUnit="en?['D','H','M','S']:['天','时','分','秒']" class="flip-down">
                                </flip-down>
                            </div>
                        </div>
                        <div class="inner" v-else-if="mode==='PLAY'" v-lazy:background="single.picture_url">
                            <div v-if="show_poster" class="wh-100 bg-black-05">
                                <div v-if="event_ended" class="flex-in-middle h-100" @click="handleEndFromPlay">
                                    <h3 class="text-white">{{ $t('MESSAGE.EVENT-CLOSED') }}</h3>
                                </div>
                                <div v-else class="flex-in-middle h-100" @click="toggleShowPoster">
                                    <img src="~@/assets/images/play_icon.png" alt="" style="width: 200px;"/>
                                </div>
                            </div>
                            <div v-else class="wh-100">
                                <nut-video :sources="video_source" :options="playback_options">
                                </nut-video>
                            </div>
                        </div>
                        <div class="inner" v-lazy:background="single.picture_url"
                             v-else-if="mode==='END' && single.close_action == 0">
                            <div class="wh-100 bg-black-05" v-if="!single.dvr_file">
                                <div class="wh-100 flex-in-middle">
                                    <h3 class="text-white text-center">{{ $t('MESSAGE.DVR-NOT-READY') }}</h3>
                                </div>
                            </div>
                            <nut-video v-else :sources="video_source" :options="playback_options"></nut-video>
                        </div>
                    </div>
                </div>

                <div class="play-window-frame" :style="{padding:play_window_padding}" style="background-color: black;"
                     v-if="mode==='PLAY' && !event_ended && single.hls2">
                    <div class="inner" v-lazy:background="single.picture_url">
                        <div v-if="show_poster" class="wh-100 bg-black-05">
                            <div class="flex-in-middle h-100" @click="toggleShowPoster">
                                <img src="~@/assets/images/play_icon.png" alt="" style="width: 200px;"/>
                            </div>
                        </div>
                        <div v-else class="wh-100">
                            <nut-video :sources="video_source2" :options="playback_options2">
                            </nut-video>
                        </div>
                    </div>
                </div>

                <div class="control-bar">
                    <div class="panel">
                        <div class="tab-select" ref="tab_select" @click="handleTabToggle">
                            <span class="tab-content mr-3"
                                  :class="{active:tab === 0}">{{ $t('TITLE.DESCRIPTION') }}</span>
                            <span class="tab-content" v-if="!single.is_comment_disabled"
                                  :class="{active:tab === 1}">{{ $t('TITLE.COMMENTS') }}</span>
                            <div class="indicator" :style="indicate_style"></div>
                        </div>
                        <div class="tips">
                            <div class="text-info d-inline-block mr-3" @click="handleSetLanguage">
                                {{ en ? '中' : 'EN' }}
                            </div>
                            <template v-if="mode==='END'">
                                <i class="el-icon-view"></i> {{ single.watched }}
                            </template>
                            <template v-else>
                                <i class="el-icon-user"></i> {{ single.online.online }}
                            </template>
                            <div class="node" @click="show_node_picker = true" v-if="mode !== 'END'">
                                {{ node_name }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="body-wrap">
                    <transition :name="!!tab?'float-left':'float-right'">
                        <div class="description " v-if="tab===0" key="description">
                            <div class="description-header">
                                <div class="title mb-1">{{ single.subject }}</div>
                                <div class="text teacher">{{ single.teacher_name }}</div>
                                <div class="text time position-relative mt-2">
                                    <div class="text department pull-right mr-1">{{ single.section_name }}</div>
                                    {{ single.good_time }}
                                </div>
                                <!-- |
                                    {{ english ? single.city_english : single.city_name }} |
                                    {{ english ? single.department_english : single.department_name }}
                                    -->
                            </div>
                            <div class="description-body vert-scroll" style="border-top:1px solid #eee">
                                <div class="mt-2 mb-3 px-2"  v-html="single.description"></div>
                                <div v-if="single.desc_picture_url" class="mt-2 mb-3 px-2">
                                    <img :src="single.desc_picture_url" class="responsive-image" alt="">
                                </div>
                                <div v-if="single.teachers && single.teachers.length > 0">
                                    <div class="el-divider el-divider--horizontal"></div>

                                    <div v-for="x in single.teachers" :key="x.id" class="mt-3 px-2"
                                         style="border-radius: 5px;font-size:14px;">
                                        <div class="teacher-image pull-left mr-3 p" v-lazy:background="x.picture_url"
                                             style="border-radius: 5px;display: inline-block">
                                        </div>
                                        <b>{{ x.name }}</b>
                                        <div class="">
                                            <div class="mt-1" v-html="x.bio"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="comment" v-else key="comment">
                            <div class="comments-window overflow-hidden">
                                <div class="wh-100 flex-in-middle" v-if="!public_comments || !public_comments.length">
                                    {{ $t('MESSAGE.NO-COMMENTS') }}
                                </div>
                                <div class="wh-100 vert-scroll" ref="comment_box">
                                    <div v-for="x in public_comments" :key="x.id">
                                        <div v-if="!!x.announce" class="comment-box announce">
                                            <div class="name">{{ $t('TITLE.ANNOUNCE') }}</div>
                                            <div class="time">{{ x.time | short_time }}</div>
                                            <div class="text"><i class="el-icon-bell"></i> {{ x.answer }}</div>
                                        </div>
                                        <div v-else class="comment-box">
                                            <div class="name">{{ x.user_name }}</div>
                                            <div class="time">{{ x.time | short_time }}</div>
                                            <div class="text">
                                                <div class="question">{{ x.comment }}</div>
                                                <div class="answer" v-if="x.answer"><i
                                                    class="el-icon-chat-line-square"></i> {{ x.answer }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="input-box d-flex mb-3" v-if="mode === 'PLAY'">
                                <div @click="showSendMessageBox" class="fake-input px-3">
                                    <i class="el-icon-edit"></i>
                                    {{
                                        $t((!user.token || !user.token.length) ? 'TITLE.SIGN-IN-TO-COMMENT' : 'MESSAGE.QUESTION')
                                    }}
                                </div>
                                <div class="fake-icon mr-3" @click="show_private_question=true" ><i class="el-icon-news"></i></div>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
        </div>
        <div v-else class="wh-100">
            <div v-if="error" class="wh-100">
                <public-frame-dark class="d-flex flex-column" style="min-height: 460px;">
                    <div v-if="error==='NOT-FOUND' || error==='NOT-INVITED'" style="width: 100%">
                        <div class="text-center">
                            <h1 style="font-size: 8em; color: #ffffff">404</h1>
                        </div>
                        <div class="text-center mt-2">
                            <h1 style="font-size: 2rem;" class="text-muted">{{ $t("MESSAGE." + error) }}</h1>
                        </div>
                        <a @click="$router.back()" class="d-block text-center mt-3" style="color: #333333"><i
                            class="el-icon-arrow-left">{{ $t('TITLE.BACK') }}</i></a>
                    </div>
                    <div v-else-if="error==='WRONG-PASSCODE'" style="width: 100%; height: 100%; padding: 10px;">
                        <div class="frame-16-9">
                            <div class="inner " v-lazy:background="single.picture_url">
                                <div class="wh-100" style="background-color: RGBA(0,0,0,0.5)"></div>
                                <div class="position-absolute wh-100" style="top: 0;left: 0;">
                                    <h3 class="mt-4 text-center text-white">{{ $t("MESSAGE.INPUT-PASSCODE") }}</h3>
                                    <nut-shortpassword @done="handlePasscode"
                                                       :class="!process.loading?'shaky':''"></nut-shortpassword>
                                </div>
                            </div>
                        </div>
                        <div class="mt-3 font-weight-bold">{{ single.subject }}</div>
                        <div class="mt-1 ">{{ single.teacher_name }}</div>
                        <div class="mt-1">{{ single.good_time }}</div>
                    </div>
                </public-frame-dark>
            </div>
            <div v-else class="flex-in-middle h-100" style="font-size: 28px;">
                <i class="el-icon-loading text-muted"></i>
                <span class="text-muted ml-3">{{ $t("MESSAGE.WAIT") }}</span>
            </div>
        </div>
        <nut-picker
            :is-visible.sync="show_node_picker"
            :list-data="node_options"
            :default-value-data="node_source"
            @confirm="handle_node_pick"
        >
        </nut-picker>
        <nut-dialog :title="$t('MESSAGE.QUESTION')" :visible="show_question_window"
                    @ok-btn-click="handleSendQuestion"
                    @cancel-btn-click="show_question_window=false" @close="show_question_window=false"
                    :ok-btn-txt="$t('TITLE.OK')" :cancel-btn-txt="$t('TITLE.CANCEL')"
        >
            <nut-textbox v-model="question" txt-area-h="100" :max-num="200" :place-text="$t('MESSAGE.QUESTION')"></nut-textbox>
        </nut-dialog>
        <nut-popup closeable get-container="body"
                   destroy-on-close
                   position="right" :style="{ width:'300px', height: '100%' }"
                   v-model="show_private_question"
                   @open="handlePopupOpen" @close="show_private_question=false"
                   close-icon-position="top-right">
            <div class="filter_wrap">
                <div class="title">{{ $t('TITLE.INPUTS') }}</div>
                <div class="body vert-scroll pt-3 pl-2" style="border-top: 1px solid #dddddd">
                    <div v-for="x in self_comments" :key="x.id">
                        <div class="comment-box">
                            <div class="name">{{ x.user_name }}</div>
                            <div class="time">{{ x.time | short_time }}</div>
                            <div class="text">
                                <div class="question">{{ x.comment }}</div>
                                <div class="answer" v-if="x.answer"><i
                                    class="el-icon-chat-line-square"></i> {{ x.answer }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nut-popup>
    </section>
</template>

<script>
import PlayMixin from "@/views/mixins/play-mixin.js";
import PublicFrameDark from "@/components/mobile-public-frame-dark";
import CommonMixin from "@/common/mixins/common-mixin"
import "@/plugins/nutui"
import "@/scss/shaky.scss"
import FlipDown from "vue-flip-down"

export default {
    components: {PublicFrameDark, FlipDown},
    mixins: [PlayMixin, CommonMixin],
    name: "Play",
    data() {
        return {
            show_description: true,
            show_question_window: false,
            tab: 0,
            show_node_picker: false,
            indicate_style: "",
            play_window_padding: "0",
            check_timer: false,
            event_ended: false,
            question: "",
            show_private_question: false,
        }
    },
    mounted() {
        const vm = this;
        setTimeout(function () {
            vm.calculateTabStyle();
        }, 150);
        window.addEventListener('resize', this.handleResize, false);
        this.handleResize();
        if (!this.closed) {
            const vm = this;
            this.check_timer = setInterval(() => {
                vm.checkClosed();
            }, 1000);
        }
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize, false)
        if (this.check_timer) {
            clearInterval(this.check_timer);
            this.check_timer = 0;
        }
    },
    computed: {
        video_source() {
            if (this.mode === "END") {
                return [{src: this.single.dvr_file, type: "video/mp4"}];
            } else if (this.mode === "PLAY") {
                const edge = this.single.hls.valueSearch(this.source, 'english');
                return [{src: edge.url, type: "video/mp4"}];
            } else {
                return null;
            }
        },
        video_source2() {
            if (this.mode === "PLAY" && this.single.hls2) {
                const edge = this.single.hls2.valueSearch(this.source, 'english');
                return [{src: edge.url, type: "video/mp4"}];
            } else {
                return null;
            }
        },
        playback_options() {
            return {
                controls: this.mode === 'END' || !this.video_source2,
                poster: this.mode === 'END' ? this.single.picture_url : null,
                playsinline: true,
                autoplay: this.mode === 'PLAY'
            };
        },
        playback_options2() {
            return {
                controls: this.mode === 'END',
                poster: this.mode === 'END' ? this.single.picture_url : null,
                playsinline: true,
                autoplay: this.mode === 'PLAY',
                muted: true,
            };
        },
        node_name() {
            if (!this.single || !this.single.hls) return "";
            const node = this.single.hls.valueSearch(this.source, 'english');
            return this.english ? node.english : node.name;
        },
        node_options() {
            const vm = this;
            if (!this.single || !this.single.hls) return null;
            const options = this.single.hls.map(item => {
                return {label: vm.english ? item.english : item.name, value: item.english};
            });
            return [options];
        },
        node_source() {
            return [this.source];
        },

    },
    methods: {
        handlePopupOpen() {
        },
        handleSendQuestion() {
            this.show_question_window = false;
            this.sendComment();
        },
        checkClosed() {
            const now = Math.floor(new Date().getTime() / 1000);
            this.event_ended = now > this.single.end_timestamp;
            if (this.event_ended) {
                this.show_poster = true;
            }
        },
        handleResize() {
            const portrait = document.body.clientWidth < document.body.clientHeight;
            if (!portrait) {
                const width = (document.body.clientWidth - 16 / 9 * document.body.clientHeight) / 2;
                this.play_window_padding = `0 ${width}px`;
            } else {
                this.play_window_padding = "0";
            }
        },
        afterReload() {
            if (!this.source) {
                this.source = this.single.hls[0].english;
                localStorage.setItem('PLAY_SOURCE', this.source);
            } else {
                const edge = this.single.hls.valueSearch(this.source, 'english');
                if (this.source !== edge.english) {
                    this.source = edge.english;
                    localStorage.setItem('PLAY_SOURCE', this.source);
                }
            }
        },
        toggleShowPoster() {
            this.show_poster = false;
        },
        handleSetLanguage() {
            this.setLanguage();
            this.$nextTick(function () {
                this.calculateTabStyle();
            })
        },
        handleTabToggle() {
            if (this.single.is_comment_disabled) this.tab = 0;
            else {
                this.tab = 1 - this.tab;
                this.calculateTabStyle();
                if (this.tab === 1) {
                    this.updateCommentBox();
                }
            }
        },
        calculateTabStyle() {
            if (!this.$refs || !this.$refs['tab_select']) return;
            window.a = this.$refs['tab_select'];
            const children = this.$refs['tab_select'].children;

            let left = 0;
            let width = 0;
            left = children[this.tab].offsetLeft;
            width = children[this.tab].clientWidth;
            this.indicate_style = `left:${left}px; width:${width}px`;
        },
        handle_node_pick(array) {
            this.source = array[0];
            localStorage.setItem('PLAY_SOURCE', this.source);
        },
        showSendMessageBox() {
            if (!this.user.token && !this.user.token.length) {
                // this.$router.push('/go-sso?_t=' + window.location.pathname);
                window.location.href = "/go/sso?_t=" + window.location.pathname;
            } else {
                this.show_question_window = true;
            }
        },
        updateCommentBox() {
            const vm = this;
            setTimeout(function () {
                if (vm.$refs['comment_box']) {
                    const div = vm.$refs['comment_box'];
                    div.scrollTop = div.scrollHeight;
                }
            }, 150)
        },
        sendComment() {
            if (this.question && this.user.token && this.user.token.length) {
                this.$api('Program.Comment', {
                    id: this.single.id,
                    uuid: this.single.uuid,
                    comment: {text: this.question, private: 0}
                }, 1).then(res => {
                    this.question = '';
                    this.updateComments(res.data.data);
                });
            }
        },
    }
}
</script>

<style lang="scss">
@import "~@/views/scss/mobile-play.scss";
</style>
