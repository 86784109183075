<template>
    <div class="w-100 h-100 mobile-public" :class="theme">

        <div class="header flex-in-middle">
            <img :src="logo" alt="logo"/>
        </div>
        <div class="body" >
            <div class="h-100 w-100 flex-in-middle">
                <slot></slot>
            </div>
            <div class="language">
                <div class="mr-2 locale" @click="setLocale">
                    {{ english ? "LANGUAGE: English" : "LANGUAGE: 中文" }}
                </div>
            </div>
        </div>
        <div class="footer flex-in-middle">
            <page-footer></page-footer>
        </div>
    </div>
</template>

<script>
import CommonMixin from "@/common/mixins/common-mixin"
import PageFooter from "@/components/page-footer";

export default {
    name: "mobile-public-frame-dark",
    components: {PageFooter},
    mixins: [CommonMixin],
    props: {
        menu: {
            type: Boolean, default() {
                return true;
            }
        },
    },
    data() {
        return {
            logo: "/assets/logo.png",
        }
    },
    computed: {
        theme() {
            return this.$organization.dark ? "" : "white";
        }
    },
    methods: {
        handleCommand(command) {
            if (command === 'logout') this.logout();
        },
        setLocale() {
            this.setLanguage(!this.english);
        }
    }
}
</script>

<style lang="scss">
.mobile-public {
    background-color: #666666;
    height: 100%;
    display: flex;
    flex-direction: column;

    .header {
        background-color: $--public-highlight;
        flex: 0 0 110px;

        img {
            max-height: 72px;
        }
    }

    .body {
        flex: 1;
        background-color: $--public-background;
        display: flex;
        box-shadow: 0 6px 6px #333333;
        border-radius: 0 0 20px 20px;
        position: relative;
        padding-bottom: 42px;
        overflow: hidden;

        .language {
            position: absolute;
            bottom: 20px;
            right: 0;
            width: 100%;
            text-align: center;
            color: #999999;
            cursor: pointer;
            transition: color ease-in 150ms;
            font-size: 14px;
            font-weight: bold;
        }
    }

    .footer {
        flex: 0 0 60px;
        color: white;
        text-align: center;
        font-size: 14px;
    }

    &.white {
        background-color: $--public-footer-background;

        .header {
            background-color: white;
        }

    }
}
</style>

